@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital:wght@500&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: "Montserrat", sans-serif;
}



/* width */
::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background-color: #0A192F;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #013f3c7f; 
    border-radius: 10px;
  }
  i {
    cursor: pointer;
  }

  i:hover {
    color: white;
  }

  .d {
     animation: d 1s infinite;
  }

  @keyframes d {
    0%{
      opacity: 1;
    }
    50%{
      opacity: 0.5;
    }
    100%{
      opacity: 0.5;
    }
  }

  .p {
    animation: p 1s infinite;
 }

 @keyframes p {
   0%{
     opacity: 0.5;
   }
   50%{
     opacity: 1;
   }
   100%{
     opacity: 0.5;
   }
 }

 .p {
  animation: p 1s infinite;
}

@keyframes p {
 0%{
   opacity: 0.5;
 }
 50%{
   opacity: 0.5;
 }
 100%{
   opacity: 1;
 }
}

input{
  height:40px;
  border: 1px solid gray;
  width: 100%;
  padding-left: 20px;
}

input:focus{
  outline: none;
  border: 3px solid grey;
}
textarea{
  border: 1px solid grey;
  height: 100px;
  padding-top: 10px;
  padding-left: 20px;
  width: 100%;
}
textarea:focus{
  outline: none;
  border: 3px solid grey;
}

.ant-model-wrap{
  z-index: 50;
}